.colorNotification{
    /* color: gray !important; */
    border: none !important;
    outline: none !important;
    font-size: 28px !important;

}

.badgeColor{
    color: rgb(255, 255, 255) !important;
}
.Notification{
    border: none !important;
    outline: none !important;
}
.Notification .MuiBadge-badge{
    background-color: grey !important;

}
.PrivateSwipeArea-root{
    right: 36px !important;
    z-index: 2000 !important;
}


.colorNotificationFullScreen,.colorNotificationLanguage{
    /* color: gray !important; */
    font-size: 28px !important;
}


/* active language here ...  */
.languageNormal{
    /* color:grey !important; */
}
.activelanguage{
    /* background-color: rgb(222, 222, 222) !important; */
    /* color:rgb(0, 0, 0) !important; */
}
.arabicDirection{
    justify-content: start !important;
    flex-direction: row-reverse;
}
/* end  */
.selectedLanguage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.subSelected{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.subSelected p{
    font-size: 14px !important;
    text-align: center;
    margin-top: 15px;
    
}