.containerOfDrawerList{
    overflow-y: scroll;
    padding-bottom:30px ;
    overflow-x: hidden !important;
}
.analyticsColorIcon{
    /* color: gray !important; */
    font-size: 28px !important;
    cursor: pointer;
}

.containerOfDrawerList::-webkit-scrollbar{
    width: 7px;
    border-radius: 10px;
}
.ListMenuItem{
    padding-top:10px;
    margin: 0 !important;
    border-radius: 10px !important;

}
.ListMenuItem:hover{
   /* background-color: #29303D !important; */

}
/* active sidemenu mode .... */
 .ListMenuItemActiveMode{
    position: relative;
    
 }
 .ListMenuItemActiveMode:hover{
   /* background-color: #29303d00 !important; */

 }

 .RTLSIDEMENU{
    display: flex;
    justify-content: start;
    padding:0 15px;
 }
 .ListMenuItemActiveModeRTL{
    /* display: flex;
    justify-content: start; */
 }
 .ListMenuItemActiveModeRTL2{
    margin-left: 20px !important;
 }
/* end ......  */

/* width */
.containerOfDrawerList::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
}
/* Track */
.containerOfDrawerList::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey; 
    border-radius: 10px;
}
/* Handle */
.containerOfDrawerList::-webkit-scrollbar-thumb {
    background: #535959d6; 
    border-radius: 5px;
}
/* Handle on hover */
.containerOfDrawerList::-webkit-scrollbar-thumb:hover {
    background: #535959; 
}
.LogoText{
    font-size: 28px !important;
    color:white !important;
    position: relative;
    top:7px;
    left:10px
}
.DrawerIconCloseHover:hover{
   background-color: rgba(235, 235, 235, 0.134) !important;
}
.AppbarIconOpenHover:hover{
    background-color: rgba(204, 204, 204, 0.597) !important;
}
.UserSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    background-color: #29303D;
    margin: 10px 15px !important;
    border-radius: 10px;

}
.UserSection >img{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}
.UserSection p{
    color: white;
    position: relative;

}
.UserSection .textUserName{
    margin-top: 10px;
}
.UserSection .textUserEmail{
    top: -16px;
    color:#94A3AE;
    font-size: 14px;
}

/* active icon mode style here ...  */
.iconModeActive{
    
    background-color: #29303d00 !important;
}
.iconModeActive img{
    width: 55px !important;
    height: 55px !important;
}
.iconModeActive p{
   font-size: 10px !important;
}
.iconModeActive .textUserEmail{
    display: none !important;
}
/* end ............................ */

.collapsedItem{
    /* background-color: transparent !important; */
    border: none !important;
    outline: none !important;
    box-shadow: none !important; 
    /* color:#fff !important; */
    margin-top: 0 !important;
    margin-bottom: 3px !important;
    margin-left: 14px !important;
    margin-right: 14px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 10px !important;
    /* background-color:#29303D !important; */

}
.collapsedItem::before{
    border: none !important;
    outline: none !important;
    display: none !important;
}
.collapsedItemActiveMode{
    margin-top: 2px !important;
    padding:0px !important;
    border-radius: 10px !important;
    /* background-color:#29303D !important; */
}

.collapsedItem:hover{
    /* background-color:#29303D !important; */
}
.expandIcon{
    color:#fff;
}
.collapsedItemIconAndName{
    display: flex;
    align-items: center;
    justify-content: center;
 
}
/* active mode here  */
.collapsedItemIconAndNameActiveMode{
    position: relative;
    left: 12px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
/* end */
.dividerList{
    scroll-margin-bottom: 10px !important;
    /* margin-top: 10px !important; */
    background-color: rgb(41,48,61) !important;
     padding: 1px 0px !important;
    margin: 15px 17px 5px 17px !important; 
    border-radius: 10px !important;
}
.ListIconColor{
    /* color: white; */
}
 /* active mode here ...  */
.ListIconColorActiveModeMenu{
    position: relative;
    left: 6px;
    padding: 3px;
    border-radius: 7px;
    transform: scale(1.2);
    text-align: center;

}
.ListIconColorActiveModeMenu2{
    position: relative;
    left: -12px;
    padding: 3px;
    border-radius: 7px;
    transform: scale(1.2);
    transform: scale(1.2);

}
.ListIconColorActiveModeMenuRTL{
    position: relative !important;
    left: -10px !important;
    padding: 3px ;
    border-radius: 7px;
    transform: scale(1.2);
    transform: scale(1.2);

}
.ListIconColorActiveModeMenuRTL:hover,
.ListIconColorActiveModeMenu:hover{
    /* background-color: #29303D !important; */
}

.collapsedItem .ListMenuItem:hover{
    /* background-color: rgba(255, 255, 255, 0.504) !important; */
}

.endListMenu{
    width: 100%;
    padding: 10px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.endListMenu video{
    width: 120px;
    height: 120px;
    filter: brightness(0.3);
    border-radius: 50%;
}

.ContentOfRigtSide{
    height: 100vh !important;
    width: 100% !important;
    padding: 3% 1% !important;
    /* background-color: #29303D; */
   /* display: flex !important;
    justify-content: center !important;
    flex-direction: column; */
    /* width: 100% !important; */
}
body::-webkit-scrollbar{
    display: none !important;
    appearance: none !important;
}


/* settings .........  */
.showSettings{
    position: fixed;
    /* top: 30%; */
    bottom: 3%;
    right: 2%;
    width: 60px;
    height: 60px;
    padding: 10px 2px;
    /* background-color: rgb(250,169,25); */
    z-index: 1000;
   border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 10px 9px 28px -3px rgba(0,0,0,0.6);
    -moz-box-shadow: 10px 9px 28px -3px rgba(0,0,0,0.6);
    box-shadow: 10px 9px 28px -3px rgba(0,0,0,0.6);
    animation: floating 3s ease-in-out infinite;
    /* align-items: center; */
}
.iconSettings{
    font-size: 40px !important;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
    cursor: pointer;
}

.rightActiveMode{
    /* top: 30%; */
    bottom: 3%;
    /* right: 97.5% !important; */
    right: 94% !important;
    left: 1% !important;
}

@media only screen and (max-width:1150px) {
    .rightActiveMode{
        /* top: 30%; */
        bottom: 3%;
        /* right: 97.5% !important; */
        right: 93% !important;
        left: 1% !important;
    }
    
}
@media only screen and (max-width:950px) {
    .rightActiveMode{
        /* top: 30%; */
        bottom: 3%;
        /* right: 97.5% !important; */
        right: 89% !important;
        left: 1% !important;
    }
    
}

@keyframes floating {
    0% {
      transform: translate3d(0, 0, 0);
    }
    45% {
      transform: translate3d(0, -10%, 0);
    }
    55% {
      transform: translate3d(0, -10%, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

@media only screen and (max-width:991px){
    .showSettings{
        width: 50px;
        height: 50px;
    }
    .iconSettings{
        font-size: 30px !important;
    }
}

/* end  */



/* ..app bar user section  */
.appBar{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}
.lefOrRightSideofAbbBar{
    display: flex;
    justify-content: end;
    align-items: center;
    width: 50%;
}
.userAppheader {
    margin: 0 16px;
    width: 21%;
    height: 55px;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    margin-right: 30px;
    border-radius: 40px;
    cursor: pointer;
   /* background-color: rgb(231, 231, 231); */

}
.userAppheader:hover{
   /* background-color: rgba(231, 231, 231, 0.549); */
}
.userAppheader:hover .imgUserAppheader{
    background-color: rgba(255, 255, 255, 0.552);
 }
.userAppheader img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}

.textuserAppher{
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: end;
    text-align: center;
    position: relative;
    top: 30%;
    
}
.textuserAppher p:nth-child(1){
    color: black;
    font-weight: 800;
    font-size: 15px;
}
.textuserAppher p:nth-child(2){
    color: rgb(89, 87, 87);
    font-weight: 400;
    font-size: 12px;
    position: relative;
    top: -17px;
}


@media only screen and (max-width:1400px) {
    .userAppheader {
        margin: 0 16px;
        width: 23%;
        height: 45px;
        margin-right: 30px;
        border-radius: 40px;
    
    }
    .textuserAppher{
        top: 37%;
    }

    .userAppheader img{
    width: 30px;
    height: 30px;
    }
    .textuserAppher p:nth-child(1){
        color: black;
        font-weight: 800;
        font-size: 13px;
    }
    .textuserAppher p:nth-child(2){
        color: rgb(89, 87, 87);
        font-weight: 400;
        font-size: 10px;
        position: relative;
        top: -17px;
    }
}
@media only screen and (max-width:1200px) {
    .userAppheader {
        margin: 0 16px;
        width: 30%;
        height: 45px;
        margin-right: 30px;
        border-radius: 40px;
    
    }
    .textuserAppher{
        top: 37%;
    }

    .userAppheader img{
    width: 30px;
    height: 30px;
    }
    .textuserAppher p:nth-child(1){
        color: black;
        font-weight: 600;
        font-size: 13px;
    }
    .textuserAppher p:nth-child(2){
        color: rgb(89, 87, 87);
        font-weight: 300;
        font-size: 10px;
        position: relative;
        top: -17px;
    }
}
@media only screen and (max-width:991px) {
    .userAppheader {
        margin: 0 16px;
        width: 35%;
        height: 45px;
        margin-right: 30px;
        border-radius: 40px;
    
    }
    .textuserAppher{
        top: 37%;
    }

    .userAppheader img{
    width: 30px;
    height: 30px;
    }
    .textuserAppher p:nth-child(1){
        color: black;
        font-weight: 600;
        font-size: 12.5px;
    }
    .textuserAppher p:nth-child(2){
        color: rgb(89, 87, 87);
        font-weight: 300;
        font-size: 10px;
        position: relative;
        top: -17px;
    }
}
@media only screen and (max-width:880px) {
    
    .userAppheader {
        margin: 0 16px;
        width: 33px;
        height: 33px;
    
    }

    .textuserAppher{
        display: none;
    }
    .textuserAppher p:nth-child(1){
        display: none;
    }
    .textuserAppher p:nth-child(2){

        display: none;

    }
}
 /* end  */