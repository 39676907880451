.node-title {
    dx: -200;
    fill: green;
  }
  
  .node-label {
  }
  
  .Tree .colorBlack{
    stroke: #000;
  }

  .Tree .colorWhite{
    stroke: ;
  }