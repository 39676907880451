.containerPagination{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination{
    overflow: hidden;
    width: fit-content;
    display: flex;
    align-items: center;
    /* justify-content: center; */
     margin: 10px 0px !important;
     padding:5px 35px !important;
     /* background-color: white; */
     border-radius: 5px;
     padding: 5px 15px;
     -webkit-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
    box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
}
.PaginationHimSelf{
    width: 100%;
}
.containerPageNumber{
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.containerPageNumber input{
    width: 50%;
    border-radius: 5px;
    outline: none !important;
    border: 1px solid grey !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}
.containerPageNumber span{
    width: 50%;
    padding: 0 3px;
}
.menuOpen{
   /* width: 80% !important; */
}
@media only screen and (max-width:991px){
    .pagination{
        /* width: 80%; */
    }
    .menuOpen{
        /* width: 90% !important; */
     }
}

@media only screen and (max-width:601px){
    .pagination{
        width: 84%;
     padding:5px 28px !important;

    }
    .menuOpen{
        /* width: 100% !important; */
     }
}