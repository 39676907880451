.containerModal{
    background-color: white ;
    width: 690px;
    height: 600px;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    border: none;
    outline: none;
    position: relative;
}

.containerModal .headerModal{
    background-color: #1E293B;
    height: 70px;
    width: 101% !important;
    display: flex;
    align-items: center;
    justify-content: start;
    color: white;
    font-size: 20px;
    border: 1px solid #1E293B;
    outline: 1px solid #1E293B;

}
.containerModal .headerModal .icon{
    color: white;
    font-size: 35px;
    margin: 0 10px;
}





.FooterModal{
   position: absolute;
   left: 0 ;
   right:0;
   bottom: 0;
   display:flex;
   align-items: center;
   justify-content: space-between;
}
.cancelButton{
    color: rgb(58, 58, 58);
    border-radius: 20px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    top: -15px;
    left: 10px;
    background-color: rgb(242, 241, 241);
}
.saveButton{
    color: rgb(58, 58, 58);
    border-radius: 20px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    top: -15px;
    right: 10px;
    background-color: rgb(242, 241, 241);
}
.cancelButton:hover{
    background-color: #1E293B;
    color: white;
}
.saveButton:hover{
    background-color: #1E293B;
    color: white;
}
.previewSection{
    color:black;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
}

/* ... content of Theme Modal Color here */
/* .contentModal{
    display: flex;
    margin: 10px 10px;
}
.contentModal .leftSide{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.contentModal .leftSide .primeryColorForText{
   border-radius: 5px;
   outline: 1px solid rgb(98, 98, 98);
   position: relative;
}
.contentModal .leftSide .primeryColorForText::before{
  position: absolute;
  content: 'Primary Color';
  top:-10px;
  left: 10px;
}
.contentModal .leftSide .primeryColorForText .colorPicker{
    display: none !important;
}
.contentModal .leftSide .primeryColorForText .lineForColor{
   
} */
/* end  */

.colorPicker{
    position:  relative !important;

}