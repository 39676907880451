@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@500&display=swap');

* {
 font-family: 'Cairo', sans-serif !important;
 -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
    display: none;
} */
#root {
    /* background-color: #edeFFF ; */
}

body{
    background-color: #F1F5F9 !important;
    /* overflow-x: hidden !important; */
}

.MuiDataGrid-columnSeparator--sideRight{
    display: none !important;
}

.react-checkbox-tree .rct-icon{
    font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif  !important;
}