.SideSettings{
    /* padding:10px; */
    /* background-color: transparent; */
    height: 100% !important;

}

/* width */
.SideSettings::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .SideSettings::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(215, 215, 215); 
    border-radius: 10px;
  }
   
  /* Handle */
  .SideSettings::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .SideSettings::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197); 
  }


.SideSettingsForNotification{
    /* padding:10px; */
    background-color: #F1F5F9 !important;
    height: 100% !important;

}

.settingHeader{
    font-size: 25px !important;
    padding:30px 10px 20px 10px;
    font-weight: 700;
    
}
.SideSettings2  .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    background-color: #F1F5F9 !important;
}

.layout_sideMenu,.layout_theme{
    width: 90%;
    height: auto;
    padding: 10px 1px ;
    border: 1px solid rgb(119, 119, 119); 
    margin: 15px;
    position: relative;
    border-radius: 5px;
}
.layout_sideMenu::before,.layout_theme::before{
    content: var(--title);
    position: absolute;
    top:-15px;
    left: 3%;
    background-color: #F1F5F9;
    padding: 2px 5px;
    font-weight: 600;
    color: rgb(70, 70, 70);
}
.ColorText,.ColorText span{
    color: black !important;
    font-size: 13px !important;
}

.listItem{
    display: flex !important;
    align-items: center !important;
    height: 40px !important;
}
.switchColor,.switchColor span{
    /* color: orange !important; */
    /* background-color: #494F5B; */
}


/* setting icon close style here ....  */
.settingsCloseIcons{
    position: absolute;
    top: 3.7%;
    right:1%;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    cursor: pointer;
    border-radius: 50px;
}
.settingsCloseIcons:hover{
   background-color: #cccccca0;
}


/* notification box settings here ... */
.settingsCloseIcons2{
    width: 100%;
    height: 100px;
    background-color: transparent;
    position: relative;
 
}
.iconCloseNotificationBox{
    position: absolute;
    top:15px;
    right:20px;
    color:grey !important;
    font-size: 27px !important;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer;
    overflow:  hidden !important;
    background-color: #11101093;

}
.iconCloseNotificationBox:hover{
    background-color: #d7d7d793;
}
.FloatingIcon{
    color:grey !important;
    font-size: 27px !important;
    pointer-events: none;
    text-align: center !important;
}
.headerSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 15px;
}
.headerSection h3{
    font-size: 25px !important;
    font-weight: 700;
}
.headerSection p{
    color:rgb(18, 109, 206);
    font-size: 12px !important;
    text-decoration: underline;
    position: relative;
    cursor: pointer;
    top: 8px;
}

.notificationItem{
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 7px;
    -webkit-box-shadow: 2px 5px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 5px 5px -4px rgba(0,0,0,0.75);
    box-shadow: 2px 5px 5px -4px rgba(0,0,0,0.75);
    margin: 5px 15px;
    position: relative;
}
.notificationItem:hover{
    -webkit-box-shadow: 2px 5px 5px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 5px 5px -5px rgba(0,0,0,0.75);
    box-shadow: 2px 5px 5px -5px rgba(0,0,0,0.75);   
}
.leftSide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
} 
.leftSide div{
    padding: 10px;
    border-radius: 50%;
    background-color: #F1F5F9;

}
.subLeftSide{
    font-size: 20px;
    color: #494F5B;
}

.rightSide{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 8px 0;
}
.rightSide p:nth-child(1){
    font-weight: 600;
    font-size: 18px;
    color: black;
    position: relative;
    top: 13px;
}
.rightSide p:nth-child(2){
    /* font-weight: 600; */
    font-size: 15px;
    color: grey;
    position: relative;
    top: -5px;
}

.iconCloseNotificationItem{
    position: absolute;
    top: 5px;
    right:5px
}
.iconCloseNotificationItem div{
    background-color: #F1F5F9;
    border-radius: 50%;
    padding: 3px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.noNotification{
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-30%, -30%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.noNotification p{
    font-size: 26px;
    font-weight: 400;
    color: grey;
    text-align: center;

}

.ListOfThemes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* flex-flow: column; */
}
.listItemImgContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content !important;
}
.listItemImgContainer:hover{
   background-color: transparent !important;
}

.ListItemImage{
  width: 130px;  
  height: 100px;
  border-radius: 5px;
}
.ListItemImage:hover{
    opacity: 0.7;
  }
.listItemImgContainer p{
    font-size: 15px;
    color: grey;
    margin: 5px 0;
}