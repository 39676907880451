/* 
.MuiDataGrid-root{
  width: 100% !important;
  -webkit-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
}
.MuiDataGrid-virtualScroller{
 

}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
}

.MuiDataGrid-virtualScroller{
  width: 20px;
}


.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 10px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #1118278d; 
  border-radius: 10px;
}
 
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #111827; 
  border-radius: 5px;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #111827; 
}


.MuiDataGrid-columnHeaderTitle 
{ font-weight: bold !important;
  overflow: visible !important;
  color: rgb(255, 255, 255) !important; 
  font-size: 1rem ;
}

.MuiDataGrid-columnHeaders
{
    background-color:#111827 !important;
}
.MuiTablePagination-displayedRows
{
    margin-top: auto;
}
.MuiTablePagination-root .MuiTablePagination-selectLabel
{
  margin-top: auto;
} */

.alignColumnRight{
  text-align: right !important;
  
}
.alignColumnLeft{
  text-align: left !important;


}

.alignColumnRight_2{
  display: flex;
  justify-content: end;
}
.alignColumnLeft_2{
  display: flex;
  justify-content: start;

}


/* .headingSection{
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  text-align: center;
  margin: 10px 0px;
  background-color: #111827;
  border-radius: 4px;
  -webkit-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
  box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
  padding: 7px 10px;
}

.headingSection p{
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  position: relative;
  top: 7px;
}
.createUser button{
  background-color: #E7E7E7 !important;
  color: black !important;
  outline: none !important;
  border: none !important;
}
.createUser button:hover{
  background-color: #d9d9d9 !important;
  color: black !important;

}
.createUser button:focus{
  background-color: #d9d9d9;
  color: black;

} */
